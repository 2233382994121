<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import Postmate from 'postmate'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    onMounted(() => {
      const handshake = new Postmate.Model({
        routeChange: data => {
          if (data.direction === 'back') {
            router.back()
          } else if (data.name) {
            router.push({ name: data.name })
          } else {
            router.push({ path: data.path })
          }
          store.commit('iframe/updateRouteAlreadyUpdated', true)
        },
        demoEnd: () => {
          store.commit('iframe/updateDemoEnd', true)
        },
        searchPage: () => {
          router.push({
            name: 'businessAddAccountMapPage'
          })
        },
        snapshotPage: () => {
          router.push({
            name: 'businessSnapshotPage'
          })
        },
        conversationPage: data => {
          store.commit('location/updateLocationId', data.locationId)
          router.push({
            name: 'conversationsDemoPage'
          })
        },
      })
      handshake.then(parent => {
        const { model } = parent
        parent.emit('load', 'onboarding loaded')
        store.commit('location/init')
        store.commit('iframe/updateIframeState', {
          handshake,
          websiteSignup: model.websiteSignup || undefined,
          firstName: model.firstName || undefined,
          authKey: model.authKey || undefined,
          isPasswordPending: model.isPasswordPending || undefined,
          snapshotName: model.snapshotName || undefined,
          snapshotId: model.snapshotId || undefined,
          snapshotType: model.snapshotType || undefined,
        })
        if (model.locationId) {
          store.commit('location/updateLocationId', model.locationId)
        }
        if (model.userData) {
          store.commit('user/set', model.userData)
        }
      })
    })
    return {}
  }
})
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: inherit;
}

#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;
}
</style>
