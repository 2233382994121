const iframe = {
  namespaced: true,
  state: {
    handshake: undefined,
    websiteSignup: true,
    firstName: '',
    routeAlreadyUpdated: false,
    authKey: '',
    demoEnd: false,
    isPasswordPending: false,
    snapshotName: '',
    snapshotId: '',
    snapshotType: ''
  } as any,
  actions: {
    updateIframeAction({ commit }, payload: any) {
      commit('updateIframeState', payload)
    }
  },
  mutations: {
    updateIframeState(state: any, payload: any) {
      state.handshake = payload.handshake
      state.websiteSignup = payload.websiteSignup
      state.firstName = payload.firstName
      state.authKey = payload.authKey
      state.isPasswordPending = payload.isPasswordPending
      state.snapshotName = payload.snapshotName
      state.snapshotId = payload.snapshotId
      state.snapshotType = payload.snapshotType
    },
    updateRouteAlreadyUpdated(state: any, value: any) {
      state.routeAlreadyUpdated = value
    },
    updateDemoEnd(state: any, value: boolean) {
      state.demoEnd = value
    }
  }
}

export default iframe
