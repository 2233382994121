import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
const BusinessInfoPage = () =>
  import(
    /* webpackChunkName: "business-info-page" */ '@/views/BusinessInfoPage.vue'
  )
const BusinessAddressPage = () =>
  import(
    /* webpackChunkName: "business-address-page" */ '@/views/BusinessAddressFormPage.vue'
  )
const BusinessToolsPage = () =>
  import(
    /* webpackChunkName: "business-tools-page" */ '@/views/BusinessToolsPage.vue'
  )
const BusinessPasswordPage = () =>
  import(
    /* webpackChunkName: "business-password-page" */ '@/views/BusinessPasswordPage.vue'
  )
const BusinessAddAccountMapPage = () =>
  import(
    /* webpackChunkName: "business-password-page" */ '@/views/BusinessAddAccountMapPage.vue'
  )
const BusinessAddAccountPage = () =>
  import(
    /* webpackChunkName: "business-password-page" */ '@/views/BusinessAddAccountPage.vue'
  )
const ConversationsDemoPage = () =>
  import(
    /* webpackChunkName: "business-password-page" */ '@/views/ConversationsDemoPage.vue'
  )
const BusinessSnapshotPage = () =>
  import(
    /* webpackChunkName: "business-snapshot-page" */ '@/views/BusinessSnapshotPage.vue'
  )
const LocationUserUpdatePage = () =>
  import(
    /* webpackChunkName: "location-user-update-page" */ '@/views/LocationUserUpdatePage.vue'
  )
import PageNotFound from '@/components/PageNotFound.vue'
import { useStore } from './../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/onboarding',
    name: 'businessInfoPage',
    component: BusinessInfoPage
  },
  {
    path: '/onboarding/address',
    name: 'businessAddressPage',
    component: BusinessAddressPage
  },
  {
    path: '/onboarding/tools',
    name: 'businessToolsPage',
    component: BusinessToolsPage
  },
  {
    path: '/onboarding/password',
    name: 'businessPasswordPage',
    component: BusinessPasswordPage
  },
  {
    path: '/onboarding/snapshot',
    name: 'businessSnapshotPage',
    component: BusinessSnapshotPage
  },
  {
    path: '/onboarding/search',
    name: 'businessAddAccountMapPage',
    component: BusinessAddAccountMapPage
  },
  {
    path: '/onboarding/account',
    name: 'businessAddAccountPage',
    component: BusinessAddAccountPage
  },
  {
    path: '/onboarding/conversations',
    name: 'conversationsDemoPage',
    component: ConversationsDemoPage
  },
  {
    path: '/location/:locationId/user-update',
    name: 'locationUserUpdatePage',
    component: LocationUserUpdatePage
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: PageNotFound }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const store: any = useStore()
  const interval = setInterval(() => {
    try {
      store.state.iframe.handshake.then(parent => {
        clearInterval(interval)
        if (
          to.path.includes('/onboarding/account') &&
          store.state.location.id
        ) {
          next({ name: 'conversationsDemoPage' })
        } else {
          next()
        }
      })
    } catch (error) {
      console.log(error)
    }
  }, 200)
})

router.afterEach((to, from) => {
  const store: any = useStore()
  if (store.state.iframe.routeAlreadyUpdated) {
    store.commit('iframe/updateRouteAlreadyUpdated', false)
  } else {
    try {
      //@ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('spm-ts', {
          router: to.fullPath
        })
      })
    } catch (error) {
      console.log(error)
    }
  }
})

export default router
