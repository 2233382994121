export interface UserData {
  firstName: string
  lastName: string
  phone: string
  password: string
  countryCode: string
  logo: string
  agencyName: string
}

const user = {
  namespaced: true,
  state: {
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    countryCode: 'US',
    logo: '',
    agencyName: 'HighLevel'
  } as UserData,
  actions: {
    set({ commit }, payload: UserData) {
      commit('set', payload)
    }
  },
  mutations: {
    set(state: any, payload: UserData) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  }
}

export default user
