const location = {
  namespaced: true,
  state: {
    id: '',
    googlePlacesId: '',
    name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    categories: '',
    addressGeo: {},
    phone: '',
    website: '',
    tooltip: false,
    demoEnd: false,
    disableAction: false,
    overlay: false,
    mapOverlay: true
  } as any,
  actions: {},
  mutations: {
    updateLocation(
      state: any,
      {
        googlePlacesId,
        name,
        address,
        city,
        _state,
        country,
        postalCode,
        categories,
        addressGeo,
        phone,
        website
      }: any
    ) {
      state.googlePlacesId = googlePlacesId
      state.name = name
      state.address = address
      state.city = city
      state.state = _state
      state.country = country
      state.postalCode = postalCode
      state.categories = categories
      state.addressGeo = addressGeo
      state.phone = phone
      state.website = website
    },
    updateLocationId(state: any, id: string) {
      state.id = id
    },
    showTooltip(state: any, tooltip: boolean) {
      state.tooltip = tooltip
    },
    updateDemoEnd(state: any, demoEnd: boolean) {
      state.demoEnd = demoEnd
    },
    showOverlay(state: any, overlay: boolean) {
      state.overlay = overlay
    },
    init(state: any) {
      state.id = ''
      state.googlePlacesId = ''
      state.name = ''
      state.address = ''
      state.city = ''
      state.state = ''
      state.country = ''
      state.postalCode = ''
      state.categories = ''
      state.addressGeo = {}
      state.phone = ''
      state.website = ''
      state.tooltip = false
      state.demoEnd = false
      state.overlay = false
      state.mapOverlay = true
    },
    disableAction(state: any, action: boolean) {
      state.disableAction = action
    },
    updateMapOverlay(state: any, overlay: boolean) {
      state.mapOverlay = overlay
    }
  }
}

export default location
