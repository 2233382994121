import { createStore, createLogger } from 'vuex'
import business from './modules/business'
import iframe from './modules/iframe'
import location from './modules/location'
import user from './modules/user'
import createPersistedState from 'vuex-persistedstate'

const debug = process.env.NODE_ENV !== 'production'

export const store = createStore({
  modules: {
    business,
    iframe,
    location,
    user
  },
  strict: debug,
  plugins: debug
    ? [createLogger(), createPersistedState()]
    : [createPersistedState()]
})

export default store

export function useStore() {
  return store
}
