const business = {
  namespaced: true,
  state: {
    industryServed: '',
    customerCount: '',
    haveWebsite: true,
    websiteUrl: '',
    tools: [],
    password: '',
    address: '',
    addressLine: '',
    country: 'US',
    region: '',
    city: '',
    zipcode: '',
    tandc: false,
    snapshotId: '',
    isReselling: undefined
  } as any,
  actions: {
    set({ commit }, payload: any) {
      commit('set', payload)
    }
  },
  mutations: {
    updateInfo(
      state: any,
      { industryServed, customerCount, haveWebsite, websiteUrl }: any
    ) {
      state.industryServed = industryServed
      state.customerCount = customerCount
      state.haveWebsite = haveWebsite
      state.websiteUrl = websiteUrl
    },
    updateTools(state: any, tools: []) {
      state.tools = tools
    },
    updatePassword(state: any, password: string) {
      state.password = password
    },
    updateAddress(state: any, payload: any) {
      state.address = payload.address
      state.addressLine = payload.addressLine
      state.country = payload.country
      state.region = payload.region
      state.city = payload.city
      state.zipcode = payload.zipcode
      state.isReselling = payload.isReselling
    },
    updateTermAndCondition(state: any, tandc: boolean) {
      state.tandc = tandc
    },
    set(state: any, payload: any) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  }
}

export default business
