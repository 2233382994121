<template>
  <div class="flex min-h-screen justify-center items-center">
    <div>
      Page not found.
    </div>
    <div class="text-indigo-500 cursor-pointer underline">
      <router-link
        :to="{
          name: 'businessInfoPage',
          params: { id: '123asr4gfhfg54c355f' }
        }"
        >Go to Home.</router-link
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
